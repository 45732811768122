<template>
  <div>
    <Sidebar/>
    <div class="not-found">
      <h2 class="text">{{$t("message.fail-page")}}</h2>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
export default {
  name: 'NotFound',
  components: {Sidebar}
}
</script>

<style>
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30vh;
}
</style>